import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {SortDirection} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {DadResponseDto, InvalidDadStatusDto} from 'app/domain/dto/dad.interface';
import {MaterialDto} from 'app/domain/dto/sap-material.interface';
import {
  ServicePackageDto,
  SupportedMandatorDto,
  VendorDto
} from 'app/domain/dto/service-package.interface';
import {UserHistoryInterface} from 'app/domain/dto/user-history.interface';
import {UserConfiguration} from 'app/domain/dto/user-configuration.interface';
import {environment} from 'environments/environment';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {Pageable} from 'app/domain/dto';
import {ExternalUser} from 'app/domain/dto/external-user.interface';
import {FilterOrderDto} from 'app/domain/dto/filter-order.interface';
import {InternalUser} from 'app/domain/dto/internal-user.interface';
import {
  convertToSortParams,
  createQueryParams,
  PageableRequestParameters
} from 'app/ui/helpers/request.helper';
import {
  DamageWarning,
  DeFleetOrderDto,
  InfleetHistory,
  InFleetOrderDto,
  IntegrationWarning,
  SearchDto
} from 'app/domain/dto/fleet.interface';
import {CancelLocationDto, LocationDto} from 'app/domain/dto/location.interface';
import {AppointmentDto} from '../modules/appointments/calendar/calendar.interfaces';
import {
  FleetTransportInternalReportDto,
  FleetTransportOrderDto,
  FleetTransportUploadDeliveryDateDto
} from '../domain/dto/fleet-transports.interface';
import {AppointmentTypeDto} from '../domain/dto/appointment-type.interface';
import {HumanResourcesDto} from '../domain/dto/human-resources.dto';
import {DeRegistrationTaskReportDto} from '../domain/dto/de-registration.interface';
import {UpdateDoorstepTransportOrderDto, UpdateFleetTransportProcessDto } from '../domain/dto/fleet-transports.interface';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({providedIn: 'root'})
export class BackofficeService implements OnDestroy {
  baseUrl = environment.baseUrl;

  private subscriptions: Subscription[] = [];

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
  }

  checkUserEmail(email: string): Observable<any> {
    const url = this.baseUrl + '/api/users/type?email=' + email;
    return this.http.get<any>(url, httpOptions);
  }

  getInternalUsers(pageNumber: number, size: number, sort: string = 'id', order: string = 'asc'): Observable<Pageable<InternalUser>> {
    let url = this.baseUrl + `/api/users/internal?page=${pageNumber}&size=${size}`;

    sort.split(',').forEach((part: string) => {
      url += `&sort=${part}`;
      if (order.length > 0) {
        url += `,${order}`;
      }
    });

    return this.http.get<Pageable<InternalUser>>(url, httpOptions);
  }

  getExternalUsers(pageNumber: number, size: number, sort: string = 'id', order: string = 'asc'): Observable<Pageable<ExternalUser>> {
    let url = this.baseUrl + `/api/users/external?page=${pageNumber}&size=${size}`;
    sort.split(',').forEach((part: string) => {
      url += `&sort=${part}`;
      if (order.length > 0) {
        url += `,${order}`;
      }
    });

    return this.http.get<Pageable<ExternalUser>>(url, httpOptions);
  }

  getUsersHistory(type: string, parameters: PageableRequestParameters): Observable<Pageable<UserHistoryInterface>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api/user-history?type=${type}&${params}`;

    return this.http.get<Pageable<UserHistoryInterface>>(url, httpOptions);
  }

  createInternalUser(value: InternalUser): Observable<any> {
    const url = this.baseUrl + '/api/users/internal';
    return this.http.post<InternalUser>(url, value, httpOptions);
  }

  createExternalUser(value: ExternalUser): Observable<any> {
    const url = this.baseUrl + '/api/users/external';
    return this.http.post<ExternalUser>(url, value, httpOptions);
  }

  toggleInternalUserState(id: number, state: boolean) {
    const url = this.baseUrl + `/api/users/internal/${id}/status`;
    return this.http.post<any>(url, {
      active: state
    }, httpOptions);
  }

  toggleExternalUserState(id: number, state: boolean) {
    const url = this.baseUrl + `/api/users/external/${id}/status`;
    return this.http.post<any>(url, {
      active: state
    }, httpOptions);
  }

  getInternalUserById(id: number): Observable<InternalUser> {
    const url = this.baseUrl + `/api/users/internal/${id}`;
    return this.http.get<InternalUser>(url, httpOptions);
  }

  updateInternalUser(user: InternalUser): Observable<any> {
    const url = this.baseUrl + `/api/users/internal/${user.id}`;
    return this.http.patch<InternalUser>(url, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles,
      phone: user.phone
    }, httpOptions);
  }

  getExternalUserById(id: number): Observable<ExternalUser> {
    const url = this.baseUrl + `/api/users/external/${id}`;
    return this.http.get<ExternalUser>(url, httpOptions);
  }

  updateExternalUser(user: ExternalUser): Observable<any> {
    const url = this.baseUrl + `/api/users/external/${user.id}`;
    return this.http.patch<ExternalUser>(url, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles,
      phone: user.phone,
      vendorId: user.vendorId,
      location: user.location
    }, httpOptions);
  }

  removeUser(id: number) {
    const url = this.baseUrl + `/api/users/${id}`;

    return this.http.delete<any>(url, httpOptions);
  }

  forgotPasswordRequest(email: string): Observable<any> {
    const url = this.baseUrl + `/api/auth/password?email=${email}`;
    return this.http.get<any>(url, httpOptions);
  }

  changePasswordRequest(oldPassword: string, newPassword: string): Observable<any> {
    const url = this.baseUrl + `/api/auth/password`;
    return this.http.put<any>(url, {
      newPassword: newPassword,
      oldPassword: oldPassword
    }, httpOptions);
  }

  unblockExternalUser(id: number) {
    const url = this.baseUrl + `/api/users/external/${id}/unblock`;
    return this.http.post<ExternalUser>(url, httpOptions);
  }

  assignMultipleFleetTransportOrdersToVendor(orderDetails: {contractId: string, vendorId: number}[]): Observable<any[]> {
   const requests = orderDetails.map(details => {
     return this.assignVendorToFleetTransportOrder(details);
   });
    return forkJoin(requests);
  }

  assignVendorToFleetTransportOrder(orderDetails: {contractId: string, vendorId: number}): Observable<any> {
    const url = this.baseUrl + `/api-gateway/fleet/transport/${orderDetails.contractId}/approve`;
    return this.http.post<any>(url, {
      vendorId: orderDetails.vendorId
    }, httpOptions);
  }

  getVendors(pageIndex: number = 0,
             pageSize: number = 1000,
             sortActive: string = 'id',
             sortDirection: SortDirection = 'asc'): Observable<Pageable<VendorDto>> {
    let url = this.baseUrl + `/api-gateway/sap/vendors?page=${pageIndex}&size=${pageSize}`;
    if (sortActive.length > 0) {
      url += `&sort=${sortActive},`;
    }
    if (sortDirection.length > 0) {
      url += `${sortDirection}`;
    }

    return this.http.get<Pageable<VendorDto>>(url, httpOptions);
  }

  searchOrders(type: String, query: String): Observable<FilterOrderDto[]> {
    const url = this.baseUrl + `/api-gateway/orders/search?type=${type}&value=${query}`;
    return this.http.get<FilterOrderDto[]>(url, httpOptions);
  }

  getDadWarnings(page: number = 0, size: number = 100,
                 sortParam: string = 'contractId',
                 direction: ('asc' | 'desc' | '') = ''): Observable<Pageable<InvalidDadStatusDto>> {
    const url = this.baseUrl + `/api-gateway/statuses/invalid`;
    const params = `?page=${page}&size=${size}&sort=${sortParam},${direction}`;

    return this.http.get<Pageable<InvalidDadStatusDto>>(url + params, httpOptions);
  }


  getDadResponses(page: number = 0, size: number = 100,
                  sortParam: string = 'requestTime',
                  direction: ('asc' | 'desc' | '') = 'desc'): Observable<Pageable<DadResponseDto>> {
    const url = this.baseUrl + `/api-gateway/dad/proxy/requests`;
    const params = `?page=${page}&size=${size}&sort=${sortParam},${direction}`;

    return this.http.get<Pageable<DadResponseDto>>(url + params, httpOptions);
  }

  fixMultipleDadWarnings(statusIds: string[]): Observable<any[]> {
    const requests = statusIds.map((statusId: string) => this.fixDadWarning(statusId));
    return forkJoin(requests);
  }

  fixDadWarning(statusId: string): Observable<any> {
    const url = this.baseUrl + `/api-gateway/statuses/invalid/${statusId}`;
    return this.http.post<any>(url, {}, httpOptions);
  }

  fixMultipleDadRequests(statusIds: string[]): Observable<any[]> {
    const requests = statusIds.map((statusId: string) => this.fixDadRequests(statusId));
    return forkJoin(requests);
  }

  fixDadRequests(statusId: string): Observable<any> {
    const url = this.baseUrl + `/api-gateway/dad/proxy/requests/${statusId}`;
    return this.http.patch<any>(url, {}, httpOptions);
  }

  getUserDisplayConfiguration(userId: number, type: ('EXTERNAL' | 'INTERNAL')): Observable<UserConfiguration> {
    const url = this.baseUrl + `/api/user/${userId}/config?type=${type}`;
    return this.http.get<UserConfiguration>(url, httpOptions);
  }

  setUserDisplayConfiguration(userId: number, userConfiguration: UserConfiguration): Observable<any> {
    const url = this.baseUrl + `/api/user/${userId}/config`;
    return this.http.put<any>(url, userConfiguration, httpOptions);
  }

  removeUserDisplayConfiguration(userId: number): Observable<any> {
    const url = this.baseUrl + `/api/user/${userId}/config`;
    return this.http.delete(url, httpOptions);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  createVendor(vendorDto: VendorDto): Observable<VendorDto> {
    const url = this.baseUrl + `/api-gateway/sap/vendors`;
    return this.http.post<VendorDto>(url, vendorDto, httpOptions);
  }

  getVendorById(id: string) {
    const url = this.baseUrl + `/api-gateway/sap/vendors/${id}`;
    return this.http.get<VendorDto>(url, httpOptions);
  }

  addVendorMandators(mandators: SupportedMandatorDto[]): Observable<SupportedMandatorDto[]> {
    const requests = mandators.map(
      (mandator: SupportedMandatorDto) => this.addMandator(mandator)
    );
    return forkJoin(requests);
  }

  addMandator(mandator: SupportedMandatorDto): Observable<SupportedMandatorDto> {
    const url = this.baseUrl + `/api-gateway/sap/mandators`;
    return this.http.post<SupportedMandatorDto>(url, mandator, httpOptions);
  }

  updateVendorMandators(mandators: SupportedMandatorDto[]): Observable<SupportedMandatorDto[]> {
    const requests = mandators.map(
      (mandator: SupportedMandatorDto) => this.updateMandator(mandator)
    );
    return forkJoin(requests);
  }

  updateMandator(mandator: SupportedMandatorDto): Observable<SupportedMandatorDto> {
    const url = this.baseUrl + `/api-gateway/sap/mandators/${mandator.id}`;
    return this.http.patch<SupportedMandatorDto>(url, mandator, httpOptions);
  }

  updateVendor(vendorDto: VendorDto): Observable<VendorDto> {
    const url = this.baseUrl + `/api-gateway/sap/vendors/${vendorDto.id}`;
    return this.http.patch<VendorDto>(url, vendorDto, httpOptions);
  }

  removeVendorMandators(removedMandators: SupportedMandatorDto[]): Observable<SupportedMandatorDto[]> {
    const requests = removedMandators.map(
      (mandator: SupportedMandatorDto) => this.removeMandator(mandator)
    );
    return forkJoin(requests);
  }

  removeMandator(mandator: SupportedMandatorDto): Observable<null> {
    const url = this.baseUrl + `/api-gateway/sap/mandators/${mandator.id}`;
    return this.http.delete<null>(url, httpOptions);
  }

  toggleVendorActiveState(vendorId: string, state: boolean): Observable<VendorDto> {
    const url = this.baseUrl + `/api-gateway/sap/vendors/switch/${vendorId}?active=${state}`;
    return this.http.patch<VendorDto>(url, {}, httpOptions);
  }

  getMaterialsByVendorId(vendorId: string | number): Observable<MaterialDto[]> {
    const url = this.baseUrl + `/api-gateway/sap/materials?vendorId=${vendorId}`;
    return this.http.get<MaterialDto[]>(url, httpOptions);
  }

  getServicePackageById(servicePackageId: string): Observable<ServicePackageDto> {
    const url = this.baseUrl + `/api-gateway/sap/sp/${servicePackageId}`;
    return this.http.get<ServicePackageDto>(url, httpOptions);
  }

  updateServicePackage(type: 'customer' | 'sixt' | 'driver' | 'deregistration', value: any): Observable<null> {
    const url = this.baseUrl + `/api-gateway/sap/sp/${type}`;
    return this.http.put<null>(url, value, httpOptions);
  }

  getMaterialById(id: string): Observable<MaterialDto> {
    const url = this.baseUrl + `/api-gateway/sap/materials/${id}`;
    return this.http.get<MaterialDto>(url, httpOptions);
  }

  getInFleetHistory(orderId: string) {
    const url = this.baseUrl + `/api-gateway/in-fleet/${orderId}/history`;

    return this.http.get<InfleetHistory[]>(url, httpOptions);
  }

  getInFleetOrder(id: string) {
    const url = this.baseUrl + `/api-gateway/in-fleet/${id}`;

    return this.http.get<InFleetOrderDto>(url, httpOptions);
  }

  toggleHandoverBySupplier(contractId: string, skipNotification: boolean) {
    const url = `${this.baseUrl}/api-gateway/in-fleet/orders/${contractId}`;

    return this.http.patch<InFleetOrderDto>(url, {
      skipNotification: skipNotification
    }, httpOptions);
  }

  makeReturnDoneManually(order: DeFleetOrderDto, file: Blob, eventDate: string, event: any) {
    const url = this.baseUrl + `/api-gateway/de-fleet/${order.id}/return-done`;
    const formData: FormData = new FormData();

    formData.append('contractId', order.contractId);
    formData.append('mandator', order.mandator);
    formData.append('eventDate', eventDate);
    formData.append('mileage', event.mileage);
    formData.append('locationId', event.location);
    formData.append('itemFront', event.itemLicencePlateFront);
    formData.append('itemRear', event.itemLicencePlateRear);
    formData.append('itemDoc1', event.itemRegistrationDoc1);
    if (event.salesContract !== null) {
      formData.append('salesContract', event.salesContract);
    }
    formData.append('damage', 'false');
    formData.append('returnDoc', file);

    return this.http.post<any>(url, formData);
  }

  makeHandoverDoneManually(order: InFleetOrderDto, file: Blob, eventDate: string, event: any) {
    const url = this.baseUrl + `/api-gateway/in-fleet/${order.id}/handover-done`;
    const formData: FormData = new FormData();

    formData.append('contractId', order.contractId);
    formData.append('mandator', order.mandator);
    formData.append('eventDate', eventDate);
    formData.append('mileage', event.mileage);
    formData.append('locationId', event.location);
    formData.append('handoverDoc', file);

    return this.http.post<any>(url, formData);
  }

  requestFleetOrderManually(formData: any, fleetType: string) {
    const url = this.baseUrl + `/api-gateway/${fleetType}/request/order`;

    return this.http.post<any>(url, {
      contractID: formData.contractId,
      mandator: formData.mandator
    }, httpOptions);
  }

  getInFleetOrders(status: string, filter: string, parameters: PageableRequestParameters, dealerHandover: boolean) {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const location = (typeof filter !== 'undefined' && filter.length > 0) ? `&location=${filter}` : '';
    const url = this.baseUrl + `/api-gateway/in-fleet/orders?status=${status}&${params}${location}&dealerHandover=${dealerHandover}`;

    return this.http.get<Pageable<InFleetOrderDto>>(url, httpOptions);
  }

  getDeFleetHistory(orderId: string) {
    const url = this.baseUrl + `/api-gateway/de-fleet/${orderId}/history`;

    return this.http.get<DeFleetOrderDto[]>(url, httpOptions);
  }

  getDeFleetOrder(id: string) {
    const url = this.baseUrl + `/api-gateway/de-fleet/${id}`;

    return this.http.get<DeFleetOrderDto>(url, httpOptions);
  }

  getFleetTransportOrder(id: string) {
    const url = this.baseUrl + `/api-gateway/fleet/transport/${id}`;

    return this.http.get<FleetTransportOrderDto>(url, httpOptions);
  }

  getFleetTransportOrdersForInternal(status: string, parameters: PageableRequestParameters, filter?: string) {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const vendorFilter = (typeof filter !== 'undefined' && filter.length > 0) ? `&filter=${filter}` : '';
    const url = this.baseUrl + `/api-gateway/fleet/transport/orders?status=${status}&${params}${vendorFilter}`;

    return this.http.get<Pageable<FleetTransportOrderDto>>(url, httpOptions);
  }

  getFleetTransportOrdersForVendor(vendorTab: string, parameters: PageableRequestParameters) {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/fleet/transport/orders?vendorTab=${vendorTab}&${params}`;

    return this.http.get<Pageable<FleetTransportOrderDto>>(url, httpOptions);
  }

  getFleetTransportOrdersForVendorByFilter(isFilterEnabled: Boolean, vendorTab: string, parameters: PageableRequestParameters) {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    let url = this.baseUrl + `/api-gateway/fleet/transport/orders?vendorTab=${vendorTab}&${params}`;
    if (isFilterEnabled !== null && isFilterEnabled) {
      url += `&downloaded=false`;
    }

    return this.http.get<Pageable<FleetTransportOrderDto>>(url, httpOptions);
  }

  fleetTransportReport(dto: FleetTransportInternalReportDto): Observable<any> {
    const url = this.baseUrl + '/api-gateway/fleet/transport/orders/xlsx/internal';
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({timeout: `${3600000}`})
    };
    return this.http.post<any>(url, dto, options);
  }

  deregistrationTaskReport(dto: DeRegistrationTaskReportDto): Observable<any> {
    const url = this.baseUrl + '/api-gateway/de-registration/task/xlsx';
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({timeout: `${3600000}`})
    };
    return this.http.post<any>(url, dto, options);
  }

  getFleetTransportHistory(orderId: string) {
    const url = this.baseUrl + `/api-gateway/fleet/transport/${orderId}/history`;

    return this.http.get<FleetTransportOrderDto[]>(url, httpOptions);
  }

  getDeFleetOrders(status: string, parameters: PageableRequestParameters) {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/de-fleet/orders?status=${status}&${params}`;

    return this.http.get<Pageable<DeFleetOrderDto>>(url, httpOptions);
  }

  updateFleetTransportOrder(contractId: string, value: UpdateFleetTransportProcessDto) {
    const url = this.baseUrl + `/api-gateway/fleet/transport?contractId=${contractId}`;

    return this.http.put<Pageable<FleetTransportOrderDto>>(url, value, httpOptions);
  }

  updateDoorstepTransportOrder(contractId: string, value: UpdateDoorstepTransportOrderDto) {
    const url = this.baseUrl + `/api-gateway/fleet/transport/doorstep?contractId=${contractId}`;

    return this.http.put<Pageable<FleetTransportOrderDto>>(url, value, httpOptions);
  }

  getLocations(parameters: PageableRequestParameters, role: string): Observable<Pageable<LocationDto>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/locations?${params}&type=${role}`;

    return this.http.get<Pageable<LocationDto>>(url, httpOptions);
  }

  getAppointmentResources(parameters: PageableRequestParameters): Observable<Pageable<AppointmentTypeDto>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/resources/appointment?${params}`;

    return this.http.get<Pageable<AppointmentTypeDto>>(url, httpOptions);
  }

  getAppointmentResourcesList(): Observable<AppointmentTypeDto[]> {
    const url = this.baseUrl + `/api-gateway/resources/appointments`;

    return this.http.get<AppointmentTypeDto[]>(url, httpOptions);
  }

  updateAppointmentResource(id: string, appData: AppointmentTypeDto): Observable<any> {
    const url = this.baseUrl + `/api-gateway/resources/appointment/${id}`;

    return this.http.patch(url, appData, httpOptions);
  }

  getAppointmentResource(id: string): Observable<any> {
    const url = this.baseUrl + `/api-gateway/resources/appointment/${id}`;

    return this.http.get<AppointmentTypeDto>(url, httpOptions);
  }

  addAppointmentResource(appData: AppointmentTypeDto): Observable<AppointmentTypeDto> {
    const url = this.baseUrl + '/api-gateway/resources/appointment';

    return this.http.post<AppointmentTypeDto>(url, appData, httpOptions);
  }

  getHumanResources(parameters: PageableRequestParameters): Observable<Pageable<HumanResourcesDto>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/resources/human?${params}`;

    return this.http.get<Pageable<HumanResourcesDto>>(url, httpOptions);
  }

  getHumanResourcesByLocationId(parameters: PageableRequestParameters, locationId: number): Observable<Pageable<HumanResourcesDto>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/resources/humans?locationId=${locationId}&${params}`;

    return this.http.get<Pageable<HumanResourcesDto>>(url, httpOptions);
  }

  updateHumanResource(id: string, dto: HumanResourcesDto): Observable<any> {
    const url = this.baseUrl + `/api-gateway/resources/human/${id}`;

    return this.http.patch(url, dto, httpOptions);
  }

  getHumanResource(id: string): Observable<any> {
    const url = this.baseUrl + `/api-gateway/resources/human/${id}`;

    return this.http.get<HumanResourcesDto>(url, httpOptions);
  }

  addHumanResource(dto: HumanResourcesDto): Observable<any> {
    const url = this.baseUrl + '/api-gateway/resources/human';

    return this.http.post<HumanResourcesDto>(url, dto, httpOptions);
  }

  getLocationsList(): Observable<LocationDto[]> {
    const url = this.baseUrl + '/api-gateway/locations';

    return this.http.get<LocationDto[]>(url, httpOptions);
  }

  addLocation(locationData: LocationDto): Observable<any> {
    const url = this.baseUrl + '/api-gateway/locations';

    return this.http.post<LocationDto>(url, locationData, httpOptions);
  }

  cancelLocation(cancelDto: CancelLocationDto): Observable<any> {
    const url = this.baseUrl + '/api-gateway/appointment';

    return this.http.request('delete', url, {
      body: cancelDto,
      ...httpOptions
    });
  }

  updateLocation(id: string, locationData: LocationDto): Observable<any> {
    const url = this.baseUrl + `/api-gateway/location/${id}`;

    return this.http.put(url, locationData, httpOptions);
  }

  getLocation(id: string): Observable<{
    city: string;
    country: string;
    house: string;
    postCode: string;
    street: string;
  }> {
    const url = this.baseUrl + `/api-gateway/location/${id}`;
    return this.http.get(url, httpOptions) as any;
  }

  getAppointments(locationId: string | number, from: string, to: string): Observable<AppointmentDto[]> {
    const url = this.baseUrl + `/api-gateway/appointments/${locationId}?startDate=${from}&endDate=${to}`;

    return this.http.get<AppointmentDto[]>(url, httpOptions);
  }

  getAppointment(contractId: string): Observable<AppointmentDto> {
    const url = this.baseUrl + `/api-gateway/appointment?contractId=${contractId}`;

    return this.http.get<AppointmentDto>(url, httpOptions);
  }

  setAppointment(appointment: AppointmentDto): Observable<any> {
    const url = this.baseUrl + '/api-gateway/appointment';

    return this.http.post<any>(url, appointment, httpOptions);
  }

  searchFleetOrders(fleetType: string, searchType: string, value: string): Observable<SearchDto[]> {
    const url = this.baseUrl + `/api-gateway/fleet/orders/search?fleetType=${fleetType}&type=${searchType}&value=${value}`;

    return this.http.get<SearchDto[]>(url, httpOptions);
  }

  getFleetDamageWarnings(status: string, parameters: PageableRequestParameters): Observable<Pageable<DamageWarning>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/fleet/damage/warnings?status=${status}&${params}`;

    return this.http.get<Pageable<DamageWarning>>(url);
  }

  fixMultipleFleetDamageWarnings(warningIds: string[]) {
    const requests = warningIds.map(id => this.fixFleetDamageWarning(id));

    return forkJoin(requests);
  }

  fixFleetDamageWarning(warningId: string) {
    const url = this.baseUrl + `/api-gateway/fleet/damage/${warningId}`;

    return this.http.request('PATCH', url, httpOptions);
  }

  getFleetIntegrationWarnings(status: string, parameters: PageableRequestParameters): Observable<Pageable<IntegrationWarning>> {
    return status === 'NEW' ?
      this.getFleetUnfixedIntegrationWarnings(parameters) :
      this.getFleetFixedIntegrationWarnings(parameters);
  }

  getFleetFixedIntegrationWarnings(parameters: PageableRequestParameters): Observable<Pageable<IntegrationWarning>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/warnings/fleet/fixed?${params}`;

    return this.http.get<Pageable<IntegrationWarning>>(url);
  }

  getFleetUnfixedIntegrationWarnings(parameters: PageableRequestParameters): Observable<Pageable<IntegrationWarning>> {
    const params = Object.keys(parameters).length > 0 ? createQueryParams(convertToSortParams(parameters)) : '';
    const url = this.baseUrl + `/api-gateway/warnings/fleet/unfixed?${params}`;

    return this.http.get<Pageable<IntegrationWarning>>(url);
  }

  fixMultipleFleetIntegrationWarnings(warningIds: string[]) {
    const requests = warningIds.map(id => this.fixFleetIntegrationWarning(id));
    return forkJoin(requests);
  }

  fixFleetIntegrationWarning(warningId: string) {
    const url = this.baseUrl + `/api-gateway/warnings/fleet/unfixed/${warningId}`;

    return this.http.post(url, httpOptions);
  }

  assignMultipleFleetTransportOrdersByVendor(isConfirmed: boolean, contractIds: string[]): Observable<any[]> {
    const requests = contractIds.map((contractId: string) => {
      return this.assignFleetTransportOrderByVendor(isConfirmed, contractId);
    });
    return forkJoin(requests);
  }

  assignFleetTransportOrderByVendor(isConfirmed: boolean, contractId: string): Observable<any> {
    const url = this.baseUrl + `/api-gateway/fleet/transport/${contractId}/approve`;
    return this.http.post<any>(url, {
      vendorConfirmed: isConfirmed
    }, httpOptions);
  }

  exportFleetTransportNewOrdersToExcel(ids: String[]): Observable<any> {
    const url = this.baseUrl + '/api-gateway/fleet/transport/orders/xlsx/external';
    const options = {
      responseType: 'blob' as 'json'
    };

    return this.http.post<any>(url, ids, options);
  }

  uploadDeliveryDateFleetTransport(file: Blob): Observable<any> {
    const url = this.baseUrl + `/api-gateway/fleet/transport/orders/files`;

    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(url, formData);
  }

  getFleetTransportImportDeliveryDateHistory(parameters: PageableRequestParameters): Observable<Pageable<FleetTransportUploadDeliveryDateDto>> {
    const params = createQueryParams(convertToSortParams(parameters));
    const url = this.baseUrl + `/api-gateway/fleet/transport/orders/files?${params}`;

    return this.http.get<Pageable<FleetTransportUploadDeliveryDateDto>>(url, httpOptions);
  }

  cancelFleetTransportOrders(contractId: string, cancelReason: string): Observable<void> {
    const url = `${this.baseUrl}/api-gateway/fleet/transport`;
    const body = { contractId, cancelReason };
    return this.http.request<void>('delete', url, {
      ...httpOptions,
      body
    });
  }

  cancelEmptyRunFleetTransportOrders(contractId: string, cancelReason: string): Observable<void> {
    const url = `${this.baseUrl}/api-gateway/fleet/transport/empty-run`;
    const body = { contractId, cancelReason };
    return this.http.request<void>('delete', url, {
      ...httpOptions,
      body
    });
  }

  setToIncompleteFleetTransportOrder(contractId: string) {
    const url = this.baseUrl + `/api-gateway/fleet/transport?contractId=${contractId}`;

    return this.http.patch<any>(url, httpOptions);
  }

  rejectFleetTransportOrders(contractId: string) {
    const url = this.baseUrl + `/api-gateway/fleet/transport/reject?contractId=${contractId}`;

    return this.http.delete<any>(url, httpOptions);
  }

  setDeliveryDate(contractId: string, date: string) {
    const url = this.baseUrl + `/api-gateway/fleet/transport/${contractId}/approve`;
    return this.http.post<any>(url, {
      deliveryDate: date
    }, httpOptions);
  }

}
